* {
  box-sizing: border-box;
}

body {
  background-color: #EDF2F8;
  color: #424B5A;
  font-family: "Akrobat Regular", sans-serif;

}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance:textfield;
}

.container {
  width: 100%;
  padding: 0 150px;
}
@media screen and (max-width: 1410px) {
  .container {
    padding: 0 100px;
  }
}

button{
  font-family: "Akrobat Regular", sans-serif;
}

.flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-center {
  display: flex;
  align-items: center;
}

.flex-center-btw {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .add_btn {
    margin-right: 0;
  }
}
.flex-center-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

hr {
  width: 100%;
  height: 1px;
  background-color: #C2D1D9;
  margin-bottom: 20px;
}

.dashboard_block {
  background-color: #ffffff;
  border-radius: 5px;
  min-height: 197px;
  width: 24.2%;
  display: inline-block;
  padding: 15px 15px 10px;
  margin-bottom: 30px;
  hr {
    margin: 0;
  }
}

.date_wrapper {
  color: #7F8FA4;
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  display: flex;
  align-items: center;
  img {
    margin-right: 5px;
  }
}

.block_label {
  color: #7F8FA4;
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
}

.block_value {
  color: #334150;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.block_info_item {
  margin-bottom: 10px;
}

.app-back_link {
  margin: 48px 0;
  display: inline-block;
}

.page_options_wrapper {
  width: 50%;
}

.title-block{
  h1 {
    color: #334150;
    font-size: 60px;
    line-height: 75px;
    margin-bottom: 30px;
    font-weight: 400;
  }
}


div {
  .add_btn {
    color: #0253B3;
    font-family: "Akrobat Regular", sans-serif;
    font-size: 16px;
    line-height: 20px;
    text-align: right;
    text-transform: none;
    margin-right: -5px;
    padding: 0;
    img {
      margin-left: 9px;
      -webkit-transition: all 0.3s linear;
      -moz-transition: all 0.3s linear;
      -ms-transition: all 0.3s linear;
      -o-transition: all 0.3s linear;
      transition: all 0.3s linear;
    }
    &:hover{
      background: none;
      text-decoration: underline;
      img {
        transform: rotate(180deg);
      }
    }
  }
}

div {
  .blue_btn {
    color: #0253B3;
    font-family: "Akrobat Regular", sans-serif;
    font-size: 16px;
    line-height: 20px;
    text-transform: none;
    padding: 0;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    &:hover {
      text-decoration: underline;
      background: transparent;
    }
  }
}

div {
  .red_btn {
    color: #D0021B;
    font-family: "Akrobat Regular", sans-serif;
    font-size: 16px;
    line-height: 20px;
    text-transform: none;
    padding: 0;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    &:hover {
      text-decoration: underline;
      background: transparent;
    }
  }
}
.green_text{
 color: #2CC197;
}
.red_text{
  color: #D0021B;
}
textarea{
  font-family: Akrobat Regular, sans-serif;
}

.dialog_description {
  font-family: Akrobat Regular, sans-serif;
  color: #334150;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 12px;
}

.hot_offers_dialog {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  max-height: 380px;
  overflow: auto;
  padding-right: 10px;
  &::-webkit-scrollbar {
    height: 6px;
    top: 10px;
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    -webkit-border-radius: 2px;
    border-radius: 2px;
    background: #EDF2F8;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #0253B3;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: #0253B3;
  }
  .product_item {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 16px 0;
    border-bottom: 1px solid #C2D1D9;
    &:last-of-type {
      border-bottom: none;
    }
    & > div {
     width: 100%;
    }
    .title {
      font-size: 16px;
      color: #7F8FA4;
      span {
        color: #0253B3;
      }
    }
  }
  .product_info {
    display: flex;
    align-items: center;
    width: 100%;
    & > div {
      width: 100%;
    }
    .delivery_time {
      div {
        color: #7F8FA4;
        font-size: 12px;
        font-weight: 300;
        line-height: 15px;
      }
      p {
        color: #334150;
        font-size: 14px;
        line-height: 17px;
      }
    }
    .price {
      font-weight: 600;
      font-size: 23px;
      line-height: 27px;
      span {
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
      }
    }
    .cart_icon {
      margin-left: 36px;
      -webkit-transition: all 0.2s linear;
      -moz-transition: all 0.2s linear;
      -ms-transition: all 0.2s linear;
      -o-transition: all 0.2s linear;
      transition: all 0.2s linear;
      cursor: pointer;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}

$i: 0;
@while $i <= 60 {
  $i: $i + 5;
  .gap-#{$i} {
    gap: #{$i}px;
  }
  .mt-#{$i} {
    margin-top: #{$i}px;
  }
  .mb-#{$i} {
    margin-bottom: #{$i}px;
  }
}