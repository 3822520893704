.basket_page{
  .title-block h1{
    margin-top: 50px;
  }
}
.basket{
  padding: 30px 50px 40px;
  margin-bottom: 65px;
  background: #fff;
  .transactions_columns {
    .table_header{
      .row_item{
        display: flex;
        align-items: center;
      }

    }
    .table_row {
      & > :first-child {
        width: 4%;
      }
      & > :nth-child(2) {
        width: 13%;
      }
      & > :nth-child(3) {
        width: 13%;
      }
      & > :nth-child(4) {
        width: 13%;
        div {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 95%;
        }
      }
      & > :nth-child(5) {
        width: 13%;
      }
      & > :nth-child(6) {
        width: 13%;
      }
      & > :nth-child(7) {
        width: 13%;
      }
      & > :nth-child(8) {
        width: 13%;
      }
      & > :last-child {
        width: 5%;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
  .table_container{
    .table_header .table_header{
      display: flex;
    }
  }

  .table_header{
    .row_item{
      color: #7F8FA4;
      font-size: 12px;
    }
  }

  .table_body{
    .row_item{
      color: #334150;
      font-size: 16px;
    }

  }
  .row_item_disable{
    opacity: 0.7;
    }
  .table_row {
    width: 100%;
    display: flex;
    align-items: center;
    height: 60px;
    border-bottom: 1px solid rgba(0, 0, 0,0.1);
  }
  .basket_table_btn{
    align-items: center;
    display: flex;
    button{
      background: transparent;
      cursor: pointer;
      margin-left: 20px;
    }

  }
  .amount{
    border: 1px solid rgba(194, 209, 217, 0.3);
    border-radius: 3px;
    height: 41px;
    width: 93px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 20px;
    button{
      cursor: pointer;
      background: transparent;
      height: 100%;
      display: flex;
      width: 35px;
      position: relative;

    }
    .minus{
      &:before{
        position: absolute;
        content: "";
        height: 1px;
        width: 10px;
        background: #334150;
        top:  calc(50% - 1px);
        left: calc(50% - 5px);
        opacity: 0.3;
      }
    }
    .plus{
      &:before{
        position: absolute;
        content: "";
        height: 1px;
        width: 10px;
        background: #334150;
        top:  calc(50% - 0.5px);
        left: calc(50% - 5px);
      }
      &:after{
        position: absolute;
        content: "";
        height: 10px;
        width: 1px;
        background: #334150;
        top:  calc(50% - 5px);
        left: calc(50% - 0.5px);
      }

    }
  }

}

.basket_price{
  padding-top: 20px;
  .available_funds{
    color: #334150;
    font-size: 16px;
    padding-bottom: 10px;
  }
  .all_price{
    color: #334150;
    font-size: 32px;
    line-height: 38px;
    span{
      font-family: 'Akrobat Bold', sans-serif;
      font-weight: 500;
    }
  }
}

.ru_value{
  font-family: 'Roboto', sans-serif!important;
  font-weight: 500;
}

.basket_btn_wrapper{
  padding-top: 30px;
  display: flex;
  .default_button_wrapper{
    margin-right: 15px;
  }
}
.weight_bold{
  font-weight: 600;
}
.basket_commends{
  box-sizing: border-box;
  height: 59px;
  width: 100%;
  border: 1px solid #C2D1D9;
  border-radius: 3px;
  resize: none;
  padding: 12px;
  color: #334150;
  font-size: 14px;
  &::placeholder{
    color: #7F8FA4;
  }
}

.basket_no_items{
  padding: 140px 0;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  .text{
    padding: 20px 0 10px;
    color: #334150;
    font-size: 16px;
    line-height: 20px;
  }
  span{
    color: #7F8FA4;
    font-size: 14px;
  }
}