.download-file {
  display: flex;
  align-items: center;
  margin-bottom: 41px;

  .download {
    color: #c2d1d9;
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
    box-sizing: border-box;
    border: 1px solid #c2d1d9;
    border-radius: 3px 0 0 3px;
    height: 41px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    width: 202px;
    cursor: default;
    &.download_error {
      border: 1px solid #ff0000;
    }
    span {
      color: #334150;
      max-height: 35px;
      overflow: hidden;
    }
    input {
      display: none;
    }
  }
  .default_button_wrapper .default_button {
    border-radius: 0 3px 3px 0;
    height: 41px;
    min-width: 98px;
  }
}

.own-flex-wrapper {
  display: flex;
  align-items: flex-start;
}

.copy-div {
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-top: 30px;
  font-family: "Akrobat Regular";
  color: #0253b3;
  > img {
    margin-right: 10px;
  }
  margin-left: 50px;
  cursor: pointer;
  &.green-text {
    color: #16d761;
  }
}

.price-check {
  margin-top: 30px;
}

.accounting-disabled {
  pointer-events: none;
  opacity: 0.4;
}

.price-desc {
  color: #7f8fa4;
  font-size: 12px;
  font-family: "Akrobat Light";
  margin-left: 25px;
  width: 278px;
}

.price-form {
  .price-field {
    display: flex;
    align-items: center;
    .custom_input_wrapper {
      margin-right: 50px;
    }
  }
  .auto_update_wrapper {
    padding-top: 30px;
    margin-top: 30px;
    border-top: 1px solid #c2d1d9;
    display: flex;
    flex-flow: column;
    width: 100%;
    .title {
      font-size: 32px;
      letter-spacing: 0;
      line-height: 40px;
      margin-bottom: 10px;
    }
    .auto_update_option {
      width: 650px;
      margin-top: 10px;
      padding: 12px;
      display: flex;
      flex-flow: column;
      border-radius: 3px;
      background-color: #ffffff;
      box-shadow: 0 2px 24px 0 rgba(204, 204, 204, 0.25);
      .clickable_area {
        display: flex;
        align-items: center;
        //user-select: none;
        cursor: pointer;
        & > img {
          height: 32px;
          width: 32px;
          margin-right: 12px;
        }
        .option_name {
          & > div {
            font-size: 14px;
            letter-spacing: 0;
            line-height: 18px;
            &.active {
              color: #0253b3;
            }
          }
          & > p {
            font-size: 12px;
            letter-spacing: 0;
            line-height: 16px;
            color: #7f8fa4;
            & > span {
              font-family: "Akrobat SemiBold", sans-serif;
            }
          }
        }
      }
      .fields_email {
        padding: 30px 44px 18px;
        .custom_input_wrapper {
          width: 259px !important;
        }
        .first_row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 32px;
        }
        .second_row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          & > div {
            width: 259px;
            & > img {
              height: 16px;
              width: 16px;
              margin-right: 8px;
            }
            color: #7f8fa4;
            font-size: 12px;
            line-height: 15px;
          }
        }
      }
      .fields_link {
        padding: 30px 44px 18px;
        .link_field {
          .custom_input_wrapper {
            max-width: 100% !important;
          }
        }
        .with_login_and_password {
          margin: 20px 0 32px;
          cursor: pointer;
          user-select: none;
          font-size: 14px;
          letter-spacing: 0;
          & > img {
            width: 18px;
            height: 18px;
            margin-right: 8px;
          }
        }
        .login_and_password_fields {
          display: flex;
          align-items: center;
          justify-content: space-between;
          opacity: 0.4;
          &.active {
            opacity: 1;
          }
          .custom_input_wrapper {
            width: 259px !important;
          }
        }
      }
    }
  }
}

.download-file_error {
  cursor: pointer;
  margin-left: 15px;
  svg {
    fill: #ff0000;
  }
}
