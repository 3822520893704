.content-block-wrapper{
  background: #fff;
  padding: 40px 50px;
  margin-bottom: 50px;
  .title-info{
    display: flex;
    justify-content: space-between;
    align-items: center;
    h3{
      font-weight: normal;
      color: #334150;
      font-size: 32px;
      line-height: 40px;
    }

  }
}

.info_company_block {
  position: relative;
    .add_btn{
      position: absolute;
      left: 400px;
      top: 212px;
    }
}

.info-company{
  .title{
    color: #334150;
    font-size: 32px;
    line-height: 40px;
  }
  .field-block{
    align-items: flex-end;
    display: flex;
  }
  .custom_input_wrapper{
    margin: 41px 49px 0 0;
  }
}

.change_password_fields {
  & > :last-child {
    margin: 0;
  }
}

.check_wrapper {
  padding: 7px 0 23px;
  border-bottom: 1px solid #C2D1D9;
  margin-bottom: 30px;
}

.contacts{
  padding-top: 20px;
  .block_label {
    padding-bottom: 7px;
  }
  .contacts-info{
    display: flex;
    align-items: center;
    .info{
      color: #334150;
      font-size: 23px;
      padding-right: 20px;
    }
    .info-social{
      display: flex;
      align-items: center;
      img{
        margin-right: 8px;
      }
    }
    .contacts-info-left{
      display: flex;
      min-width:250px;
    }
    .contacts-info-right{
      display: flex;
      button{
        margin-right: 20px;
      }
    }
  }
}
.content-block-wrapper{
  .contacts:last-child{
    border-bottom: 1px solid #C2D1D9;
    padding-bottom: 30px;
    margin-bottom: 30px;
  }
}


.schedule{
  margin-right: 30px;
  .block_label {
    margin-bottom: 15px;
    display: block;
  }
}

.schedule-time {
  margin-bottom: 15px;
}

.map_wrapper {
  width: 537px;
  height: 365px;
  position: relative;
  overflow: hidden;
}

.schedule_map_wrapper {
  width: 537px;
  height: 240px;
  position: relative;
  overflow: hidden;
}

.expansion-title {
  & > p {
    font-size: 23px;
    cursor: pointer;
    margin-right: 30px;
    button {
      padding: 5px;
      svg {
        font-size: 18px;
      }
    }
  }
}

.time_day {
  color: #334150;
  font-family: "Akrobat SemiBold", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  margin-right: 15px;
  width: 15px;
  display: inline-block;
}

.time_value {
  color: #334150;
  font-size: 14px;
  line-height: 17px;
  width: 15px;
}

.expansion-block {
  margin-top: 20px;
}

.schedule-time_inactive {
  .time_day, .time_value {
    color: #7F8FA4;
  }
}

.info_section_no_items {
  border-bottom: 1px solid #C2D1D9;
  padding: 20px 0 30px;
  margin-bottom: 30px;
}

.info_section_loader {
  padding: 40px 0;
}

.info_company_loader_wrapper {
  height: 324px;
}
.loader_wrapper_center {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 50vh;
  &.loader_wrapper_center_dashboard {
    height: 300px;
  }
}
