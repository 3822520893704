.comment-orders{
  background: #fff;
  padding: 40px 50px;
  margin-bottom: 50px;
  .title{
    color: #334150;
    font-size: 32px;
    padding-bottom: 20px;
  }
}

.btn_hide{
  color: #0253B3;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  background: transparent;
  margin-bottom: 20px;
  &:hover{
    opacity: 0.7;
  }
}

.comment{
  display: flex;
  padding-bottom: 20px;
  flex-flow: column;
  .comment-name{
    color: #01397C;
    font-size: 16px;
    font-weight: 600;
    padding-right: 20px;
  }
  .comment-info{
    display: flex;
    align-items: center;
    padding-bottom: 5px;
  }
  .comment-time{
    color: #7F8FA4;
    font-size: 12px;
    font-weight: 300;
  }
  .descriptions{
    color: #334150;
    font-size: 14px;
    font-weight: 300;
    line-height: 17px;
  }
}
.comment-text{
  //box-sizing: border-box;
  width: 651px;
  height:auto;
  border: 1px solid #C2D1D9;
  border-radius: 3px;
  padding: 13px 10px;
  resize: none;
  font-family: "Akrobat Regular", sans-serif;
  margin-bottom: 5px;
  overflow: hidden;
  &::placeholder{
    color: #7F8FA4;
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
  }
}
.comments-wrapper {
  &>div.comment {
    text-align: justify;
    height: inherit;
    overflow: auto;
    opacity: 1;
    width: 651px;
  }
  &.comments-wrapper-last {
    &>div.comment {
      height: 0;
      overflow: hidden;
      opacity: 0;
      padding-bottom: 0;
    }
    &>div.comment:last-child {
      height: inherit;
      overflow: auto;
      opacity: 1;
      padding-bottom: 20px;
    }
  }
}