.item_page_block{
  padding: 40px 50px;
  margin-bottom: 60px;
  background: #fff;
}

.file_add{
  display: flex;
  padding-bottom: 10px;
  align-items: center;
  cursor: pointer;
  span{
    padding-left: 7px;
    color: #0253B3;
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
  }
}

.file_add:last-of-type{
  padding: 0;
}

.item_page{
  >.item_page_block:last-of-type{
    margin-bottom: 60px;
  }
  >.item_page_block:first-of-type{
    margin-bottom: 30px;
  }
}

.refund_requests_page_block{
  .transactions_table{
    .transactions_columns {
      width: 100%;
      .table_header {
        .row_item {
          display: flex;
          align-items: center;
        }
      }
      .table_body{
        .table_row{
          & > :nth-child(3) {
            font-weight: 600;
          }
        }
      }
      .table_row {
        & > :first-child {
          width: 25%;
        }
        & > :nth-child(2) {
          width: 25%;
        }
        & > :nth-child(3) {
          width: 25%;
        }
        & > :last-child {
          width: 25%;
        }
      }
    }
  }
}

.wrapper_btn_items{
  display: flex;
  .default_button_wrapper{
    margin-right: 15px;
  }
}

.descriptions_dialog_component{
  color: #334150;
  font-size: 16px;
  line-height: 20px;
}

.block_status_info{
  display: flex;
  align-items: center;
  .text{
    color: #334150;
    font-size: 16px;
    padding-left: 5px;
  }
  .status{
    border-radius: 50%;
    height: 6px;
    width: 6px;
  }
}

.block_status_info > span.text {
  padding-bottom: 0;
}

textarea {
  font-family: "Akrobat Regular", sans-serif;
  resize: none;
  box-sizing: border-box;
  min-height: 100px;
  font-size: 14px;
  width: 100%;
  border: 1px solid #c2d1d9;
  border-radius: 3px;
  padding: 12px 11px;
  &::placeholder {
      font-family: "Akrobat Regular", sans-serif;
      color: #7f8fa4;
      font-size: 14px;
      font-weight: 300;
  }
  &:hover {
    border-color: var(--color-constants-blue-light);
  }
  &:focus {
    border-color: var(--color-constants-blue-light);
    box-shadow: 0 0 0 3px var(--color-constants-blue-lightest);
  }
  // firefox
  &:focus-visible {
    outline: 0;
  }
}
.item_page {
  .title_items{
    color: #334150;
    font-size: 32px;
    line-height: 40px;
    padding-bottom: 20px;
  }
  .descriptions_items{
    padding-bottom: 7px;
    color: #7F8FA4;
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
  }
  .text{
    padding-bottom: 23px;
    color: #334150;
    font-size: 16px;
    line-height: 18px;
  }
  .title_items_mini{
    display: flex;
    color: #334150;
    font-size: 23px;
    line-height: 29px;
    padding-bottom: 20px;
  }
  .select_wrapper{
    width: 301px;
  }
  .status_wrapper{
    .title_items_mini{
      span{
        margin-right: 5px;
      }
    }
  }
  .btn_wrapper_product{
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    padding-top: 30px;
    display: flex;
    .default_button_wrapper{
      margin-right: 15px;
    }
    .file_error {
      cursor: pointer;
      color: red;
    }
  }
}

.line_wrapper{
  background: #C2D1D9;
  width: 100%;
  height: 1px;
  margin: 30px 0;
}

.half_block_wrapper_items{
  display: flex;
  align-items: center;
  >div:first-of-type{
    width: 30%;
  }
  >div:last-of-type{
    width: 70%;
  }
}
.indent_no{
  margin: 0!important;
  padding: 0!important;
}


.info-social-wrapper{
  padding-left: 20px;
  img{
    padding-right:8px;
  }
}

.descriptions_dialog_component{
  margin-top: 30px;
  color: #334150;
  font-size: 16px;
  line-height: 20px;
}

.item_page {
    .title_items{
      color: #334150;
      font-size: 32px;
      line-height: 40px;
      padding-bottom: 20px;
    }
    .descriptions_items{
      padding-bottom: 7px;
      color: #7F8FA4;
      font-size: 12px;
      font-weight: 300;
      line-height: 18px;
    }
    .text{
      padding-bottom: 23px;
      color: #334150;
      font-size: 16px;
      line-height: 18px;
    }
    .title_items_mini{
      display: flex;
      color: #334150;
      font-size: 23px;
      line-height: 29px;
      padding-bottom: 20px;
    }
    .select_wrapper{
      width: 301px;
    }
    .status_wrapper{
      .title_items_mini{
        span{
          margin-right: 5px;
        }
      }
    }
    .btn_wrapper_product{
      border-top: 1px solid rgba(0, 0, 0, 0.2);
      padding-top: 30px;
      display: flex;
      .default_button_wrapper{
        margin-right: 15px;
      }
      .file_error {
        cursor: pointer;
        color: red;
      }
    }
}