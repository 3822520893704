.orders-main-block {
  .dashboard-section_content_orders {
    //padding: 50px 0 200px;
    padding-top: 30px;
    display: block;
    &:after {
      content: "";
      display: table;
      clear: both;
    }
  }

  .pagination_wrapper{
    padding-top: 0;
  }
}

.order-item {
  position: relative;
  float: left;
  margin-right: 2%;
  width: 23.5%;
  height: 290px;
  cursor: pointer;
  border-radius: 0;
  &:nth-child(4n) {
    margin-right: 0;
  }
  .block_info_notif {
    display: inline-block;
    color: #ffffff;
    text-align: center;
    font-size: 12px;
    line-height: 22px;
    height: 20px;
    width: 20px;
    border-radius: 3px;
    background-color: #249AF3;
    margin-top: 10px;
  }
  .block_info_item {
    .block_value {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .block_value_currency {
      min-width: 90px;
      text-align: right;
      max-width: 60%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      i {
        font-size: 15px;
      }
    }
    .handler-hover-area {
      cursor: pointer;
      position: relative;
      &:hover {
        .block_value_all {
          height: auto;
          opacity: 1;
          p {
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }
    .block_value_all_wrapper {
      &>span {
        display: inline-block;
      }
    }
    .block_value_all {
      position: absolute;
      min-width: calc(100% + 30px);
      width: 100%;
      background-color: #FFFFFF;
      border: 1px solid rgba(127,143,164,0.5);
      border-radius: 4px;
      padding: 15px;
      top: 20px;
      right: -15px;
      height: 0;
      opacity: 0;
      z-index: 1;
      overflow: hidden;
      -webkit-transition: all ease .3s;
      -moz-transition: all ease .3s;
      -ms-transition: all ease .3s;
      -o-transition: all ease .3s;
      transition: all ease .3s;
      p {
        font-family: "Akrobat Regular", sans-serif;
        color: #334150;
        width: 100%;
        font-size: 16px;
        display: none;
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #C2D1D9;
        &:first-child {
          padding-top: 0;
        }
        &:last-child {
          border-bottom: none;
          padding-bottom: 0;
        }
        span {
          i {
            font-style: normal;
          }
          &:first-child {
            max-width: 70%;
            overflow: hidden;
            word-break: break-all;
            i {
              float: none;
              display: block;
              &:last-child {
                color: #7F8FA4;
                font-size: 14px;
              }
            }
          }
          &:last-child {
            min-width: 90px;
            text-align: right;
          }
        }
      }
    }

  }
}
div.select_wrapper {
  width: 275px;
  height: 40px;
  margin-left: 30px;
  background-color: #FFFFFF;
  -webkit-transition: all ease .3s;
  -moz-transition: all ease .3s;
  -ms-transition: all ease .3s;
  -o-transition: all ease .3s;
  transition: all ease .3s;
  .select__indicators, .select__menu * {
    cursor: pointer;
  }
  &:hover {
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);
  }
  div.select__value-container {
    height: 100%;
    cursor: text;
  }
  div.select_settings {
    height: 40px;
    color: #7F8FA4;
    font-family: "Akrobat Regular", sans-serif;
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
    box-sizing: border-box;
    border: 1px solid #C2D1D9;
    border-radius: 3px;
    .select_settings_select {
      background-color: #FFFFFF;
      padding: 8px 12px;
    }
    &:after {
      transform: scaleX(0);
    }
    &:before {
      border: none;
    }
    &:hover {
      &:before {
        border: none!important;
      }
    }
  }
}
.select_settings_menu {
  border-radius: 3px;
  background-color: #FFFFFF;
  color: #7F8FA4;
  font-family: "Akrobat Regular", sans-serif;
  font-size: 14px;
  font-weight: 300;
  padding: 0 12px;
  min-width: 275px;
  border: 1px solid #C2D1D9;
  option {
    padding: 4px 0;
    cursor: pointer;
  }
}
.select_settings_icon {
  font-size: 16px!important;
  margin-right: 10px;
  margin-top: 4px;
}
.status_block {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
}

.status_title {
  color: #7F8FA4;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  span {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 5px;
  }
}

.order_title {
  margin: 15px 0 10px;
  display: flex;
  justify-content: space-between;
  a {
    text-decoration: none;
  }
  h5 {
    color: #01397C;
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
  }
}

.block_sub_value {
  color: #7F8FA4;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  svg {
    margin-right: 4px;
    fill: gray;
    font-size: 12px;
  }
}

.block_value_currency {
  color: #334150;
  font-family: "Akrobat Regular", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
  i {
    font-family: 'Roboto', sans-serif;
    font-size: 22px;
    font-style: normal;
  }
}

.order_bottom_info {
  display: flex;
  justify-content: space-between;
  margin: 6px 0;
  .block_value_currency {
    font-size: 23px;
    line-height: 27px;
    align-self: flex-end;
    max-width: 60%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
