.my_orders{
  padding-top: 10px;
  .transactions_columns {
    .table_header{
      .row_item{
        display: flex;
        align-items: center;
      }
    }
    .table_row {
      & > :first-child {
        width: 15%;
      }
      & > :nth-child(2) {
        width: 20%;
      }
      & > :nth-child(3) {
        width: 15%;
      }      
      & > :nth-child(4) {
        font-size: 20px;
        width: 20%;
        >div{
          font-weight: 600;
        }
      }
      & > :nth-child(5) {
        width: 15%;
        display: flex;
      }
      & > :last-child {
        width: 15%;
        justify-content: flex-end;
        display: flex;
        button{
          cursor: pointer;
          background: transparent;
          margin-right: 30px;
        }
      }
    }
  }
  .amount{
    width: 93px;
    border: 1px solid rgba(194, 209, 217, 0.3);
    border-radius: 3px;
    height: 43px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button{
      cursor: pointer;
      background: transparent;
      height: 100%;
      display: flex;
      width: 35px;
      position: relative;

    }
    .minus{
      &:before{
        position: absolute;
        content: "";
        height: 1px;
        width: 10px;
        background: #334150;
        top:  calc(50% - 1px);
        left: calc(50% - 5px);
        opacity: 0.3;
      }
    }
    .plus{
      &:before{
        position: absolute;
        content: "";
        height: 1px;
        width: 10px;
        background: #334150;
        top:  calc(50% - 0.5px);
        left: calc(50% - 5px);
      }
      &:after{
        position: absolute;
        content: "";
        height: 10px;
        width: 1px;
        background: #334150;
        top:  calc(50% - 5px);
        left: calc(50% - 0.5px);
      }

    }
  }

  .pagination_wrapper{
    padding: 30px 0 0 0;
  }

}

.descriptions_row{
  color: #7F8FA4;
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
}


.my_orders_search{
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  input{
    font-family: "Akrobat Regular", sans-serif;
    border-radius: 3px;
    padding: 0 35px 0px 12px;
    height: 41px;
    width: 660px;
    border: 1px solid #C2D1D9;
    color: #334150;
    font-size: 14px;
    &::placeholder{
      color: #7F8FA4;
    }
  }
  .btn_search{
    position: absolute;
    cursor: pointer;
    right: 12px;
    top: calc(50% - 9px);
    background: transparent;
  }
}



.no_item_orders{
  padding: 100px 0 70px 0;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  .title_no_item{
    padding: 20px 0 10px 0;
    color: #334150;
    font-size: 16px;
  }
  .descriptions_no_item{
    color: #7F8FA4;
    font-size: 14px;
  }
}
.order-dialog-btn{
  .default_button_wrapper{
    margin: 0!important;
  }
}
.custom_count_field {
  display: inline-block;
}

.count_field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 3px;
  height: 40px;
  width: 93px;
  &.count_field_disabled {
    opacity: 0.7;
  }
  input[type=number]::-webkit-outer-spin-button,
  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type="number"]{
    -moz-appearance: textfield;
  }
  input {
    font-family: "Akrobat Regular", sans-serif;
    text-align: center;
    font-size: 16px;
    line-height: 18px;
    color: #334150;
    background-color: transparent;
    width: 30px;
    &:disabled {
      color: #334150;
    }
  }
  button {
    height: 100%;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    background-color: transparent;
    font-size: 30px;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    &:disabled {
      opacity: 0.7;
      cursor: default;
      &:hover {
        background-color: transparent;
      }
    }
    &:hover {
      background-color: rgba(51, 65, 80, 0.1);
    }
    svg {
      font-size: 20px;
      fill: #334150;
    }
  }
}
.delete_border{
  border-bottom: none;
}