.info-head {
  height: 200px;
  background-color: #01397C;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  padding-bottom: 40px;
  .edit_btn {
    align-self: flex-end;
    background: transparent;
    box-shadow: none;
    color: #EFF2F4;
    font-family: "Akrobat Regular", sans-serif;
    font-size: 16px;
    line-height: 20px;
    text-transform: none;
    padding: 0;
    margin-right: -5px;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    img {
      margin-left: 9px;
    }
    &:hover{
      text-decoration: underline;
      background: transparent;
    }
  }
}

.info-header{
  display: flex;
  padding-bottom: 11px;
}

.info-head-wrapper{
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  background-color: #01397C;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.info-head_content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.info-head_title {
  font-size: 60px;
  font-weight: 600;
  line-height: 69px;
}

.info-head_contacts {
  display: flex;
  margin-top: 20px;
  p {
    display: inline-flex;
    align-items: center;
    margin-right: 70px;
    svg, img {
      margin-right: 10px;
    }
  }
}

.search_wrapper_dashboard {
  margin: 50px 0;
  .search_product_wrapper {
    position: relative;
    input  {
      max-width: 50%;
      width: 740px;
      background-color: #FFFFFF;
      border-radius: 3px;
      border: 1px solid #C2D1D9;
      color: #7F8FA4;
      font-family: "Akrobat Light", sans-serif;
      font-size: 14px;
      font-weight: 300;
      padding: 0 12px;
      height: 41px;
      line-height: 41px;

    }
    &:after {
      content: "";
      position: absolute;
      top: 12px;
      left: 710px;
      width: 20px;
      height: 20px;
      background: url("../../assets/image/search_grey.png") no-repeat;
    }
  }
}

.section_link_wrapper {
  margin-bottom: 50px;
  a{
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    &:hover{
      text-decoration: underline;
    }
  }
}

.section_link {
  color: #0253B3;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  img {
    margin-left: 5px;
  }
}

.dashboard-section_head {
  margin-bottom: 30px;
  h4 {
    color: #334150;
    font-size: 60px;
    line-height: 75px;
    font-weight: 400;
  }
}

.dashboard-section_content {
  padding: 50px 0 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}