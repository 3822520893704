.order_item {
    margin-top: -20px;
    .my_orders_table {
        padding: 0;
    }
    .transactions_columns {
        .table_header {
            .row_item {
                display: flex;
                align-items: center;
            }
        }
        .table_body {
            .table_row {
                .row_orders {
                    & > :nth-child(2) {
                        font-weight: 600;
                    }
                    & > :nth-child(6) {
                        font-weight: 600;
                    }
                }
            }
        }
        .table_row {
            align-items: flex-start;
            height: auto;
            flex-flow: column;
            .row_orders {
                width: 100%;
                display: flex;
                height: 40px;
                align-items: center;
                & > :first-child {
                    width: 10%;
                    > div {
                        padding-left: 12px;
                        font-weight: 600;
                    }
                }
                & > :nth-child(2) {
                    width: 6%;
                    > div {
                        font-weight: 600;
                    }
                }
                & > :nth-child(3) {
                    width: 15%;
                    > div:first-of-type {
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        width: 90%;
                        font-weight: 600;
                    }
                }
                & > :nth-child(4) {
                    width: 5%;
                    display: flex;
                }
                & > :nth-child(5) {
                    width: 5%;
                }
                & > :nth-child(6) {
                    width: 5%;
                    font-weight: 400 !important;
                }
                & > :nth-child(7) {
                    width: 5%;
                    .ru_value {
                        font-weight: 500;
                    }
                }
                & > :nth-child(8) {
                    width: 9%;
                    > div {
                        font-weight: 600;
                    }
                }
                & > :nth-child(9) {
                    width: 9%;
                }                
                & > :nth-child(10) {
                    width: 10%;
                }
                & > :nth-child(11) {
                    width: 14%;
                    > div:first-of-type {
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        width: 90%;
                    }
                }
                & > :nth-child(12) {
                    width: 7%;
                    > div {
                        font-weight: 600;
                    }
                }
                & > :nth-child(13) {
                    width: 5%;
                }

            }
        }
    }
    .info_items {
        padding: -1px 0 20px;
        display: flex;
        width: 100%;
        flex-flow: column;
        .name {
            display: flex;
            padding-bottom: 20px;
            align-items: center;
            div {
                padding-right: 10px;
                color: #334150;
                font-size: 16px;
                font-weight: 600;
            }
            span {
                color: #7f8fa4;
                font-size: 12px;
            }
        }
        .descriptions {
            padding-bottom: 10px;
            color: #7f8fa4;
            font-size: 12px;
        }
        .comment {
            color: #334150;
            font-size: 16px;
        }
    }
    .block_status {
        display: flex;
        align-items: center;
    }
    .status_orders {
        height: 6px;
        width: 6px;
        display: flex;
        border-radius: 50%;
        margin-right: 5px;
    }
    .blue {
        background: #249af3;
    }
    .green {
        background: #16d761;
    }
    .red {
        background: #d0021b;
    }
    .yellow {
        background: #d5dc00;
    }
    .pagination_wrapper {
        padding: 30px 0 0 0;
    }
}
.btn_arrow_up {
    transform: rotate(180deg);
}

.filter-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    .select_wrapper {
        width: 200px;
    }
}

.tooltip {
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
    max-width: 250px;
    font-size: 16px !important;
    font-weight: 600;
    word-wrap: break-word;
}

.date_picker_wrapper {
    box-sizing: border-box;
    margin-left: 30px;
    display: flex;
    justify-content: space-between;
    .date_picker_inner {
        box-sizing: border-box;
        border: 1px solid #c2d1d9;
        border-radius: 3px;
        background-color: #ffffff;
        width: 160px;
        height: 41px;
        display: flex;
        align-items: center;
        padding: 0 12px;
        > div {
            width: 100%;
        }
        .react-datepicker__tab-loop {
            width: 0;
        }
        p {
            color: #7f8fa4;
            font-size: 14px;
            font-weight: 300;
            line-height: 18px;
            // padding-right: 35px;
        }
        input {
            color: #334150;
            height: 37px;
            cursor: pointer;
            font-family: "Akrobat", sans-serif;
            font-size: 14px;
            padding-left: 20px;
            width: 100%;
        }
    }
    .date_picker_inner:first-of-type {
        margin-right: 13px;
    }
}

.time_separator {
    font-size: 16px;
    color: #334150;
    font-family: "Akrobat Bold";
    padding: 10px 0;
    border-bottom: 1px solid #e5e5e5;
}

.red-status {
    background-color: rgba(208, 2, 27, 0.1);
}

.green-status {
    background-color: rgba(22, 215, 97, 0.1);
}

.yellow-status {
    background-color: rgba(215, 215, 22, 0.1);
}
.confirmed-status {
    background-color: rgba(22, 64, 215, 0.1);
}
