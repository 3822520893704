.panel_page {
    .ml20px {
        margin-left: 20px;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    .search_transactions_panel {
        width: 471px;
        .search_product_wrapper {
            width: 100%;
        }
        .search_input_icon {
            display: flex;
            align-items: center;
            padding-right: 12px;
            cursor: pointer;
        }
    }
}

.select_wrapper {
    width: 201px;
    height: 41px;
    .select__menu {
        .select__menu-list {
            padding: 0 8px !important;
            > div {
                color: #7f8fa4;
                box-sizing: border-box;
                border-bottom: 1px solid #c2d1d9;
                padding: 15px 0px !important;
                background: #fff !important;
                cursor: pointer;
                &:hover {
                    background: #fff !important;
                }
            }
            > div:last-of-type {
                border-bottom: none;
            }
        }
    }
}
.select__value-container:hover {
    cursor: text;
}

.select__indicators:hover {
    cursor: pointer;
}
