@import '../../../style/variables.scss';
.price_inner-content {
  padding: 50px 50px 40px 50px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .Toastify__toast-body > div:last-child {
    font-size: 15px;
  }
  .search_wrapper_price {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    @media (max-width: 1120px) {
      flex-direction: column;
    }
    .search_wrapper {
      width: 50%;
      @media (max-width: 1120px) {
        width: 100%;
      }
      .search_input_icon {
        display: flex;
        align-items: center;
        width: 34px;
      }
    }
  }
  .price_inner__buttons {
    @media (max-width: 1120px) {
      margin-top: 20px;
    }
  }
  .price_inner__btn-download {
    margin-right: 40px;
  }

  .table {
    width: 100%;
    // border-bottom: 1px solid #c2d1d9;
    .table_head {
      box-sizing: border-box;
      background-color: #edf2f8;
      height: 30px;
    }
    .table_body {
      & > div:first-child {
        position: relative;
      }
      .ReactVirtualized__Grid.ReactVirtualized__List {
        overflow: hidden auto !important;
        &::-webkit-scrollbar {
          width: 10px;
          margin-left: 10px;
        }
        &::-webkit-scrollbar-track {
          background-color: #edf2f8;
          border-radius: 2.5px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #01397c;
          border-radius: 2.5px;
        }
      }
      .no_item {
        margin-top: 12px;
      }
      .ReactVirtualized__List {
      }
      .ReactVirtualized__Grid__innerScrollContainer {
        width: inherit !important;
      }
    }
    .col {
      border-right: 1px solid #c2d1d9;
      vertical-align: middle;
      padding: 0 8px;
      display: flex;
      align-items: center;
      font-size: 12px;
      position: relative;
      .search_highlight {
        display: none;
        &.visible {
          display: block;
          width: 100%;
          font-size: 12px;
          line-height: 15px;
          min-height: 15px;
          color: #334150;
          white-space: nowrap;
          overflow: hidden;
          letter-spacing: 0.7px;
          text-overflow: ellipsis;
          span {
            font-weight: 900;
            background-color: #fdd0b3;
            padding: 1px 0px;
            display: inline-block;
            margin: 0 1px;
          }
        }
      }
      .field {
        display: none;
        &.visible {
          display: block;
          background-color: transparent;
          color: #334150;
          font-size: 12px;
          line-height: 15px;
          width: 100%;
          height: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          letter-spacing: 0.7px;
          font-family: 'Akrobat Regular';
          padding: 0 2px;
          &:focus {
            & + p {
              display: block;
            }
          }
        }
      }

      .add_field {
        display: block;
        background-color: transparent;
        color: #334150;
        font-size: 13px;
        line-height: 15px;
        width: 100%;
        height: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: 'Akrobat Regular';
        padding: 0 2px;
        &:focus {
          & + p {
            display: block;
          }
        }
      }

      .select_component {
        display: block;
        position: absolute;
        bottom: 8px;
        left: -5px;
        z-index: 2;
        background-color: transparent;
        font-size: 12px;
        width: calc(100% + 10px);
        .select__control--is-focused {
          .indicator_active {
            svg {
              transform: rotate(0);
            }
          }
        }
        .select__indicator-separator {
          display: none;
        }
        .select__control {
          z-index: 12;
          position: relative;
          top: 43px;
          margin: 0 7px;
          border: 1px solid #c2d1d9;
        }
        .select__menu {
          z-index: 10;
          padding-right: 5px;
          padding-bottom: 8px;
          &-list {
            margin-top: 50px;
            height: 244px;
            &::-webkit-scrollbar {
              width: 3px;
              margin-left: 10px;
            }
            &::-webkit-scrollbar-track {
              background-color: #edf2f8;
              border-radius: 2.5px;
            }
            &::-webkit-scrollbar-thumb {
              background-color: #01397c;
              border-radius: 2.5px;
            }
          }
        }
      }
      .producer_name {
        display: block;
        position: unset;
        bottom: 0;
        left: auto;
        width: 100%;
        border: none;
        background-color: inherit;
        padding: 0;
        font-size: 12px;
      }

      &-btn {
        display: block;
        position: absolute;
        top: 1px;
        left: 100%;
        padding-left: 7px;
        opacity: 0;
        cursor: pointer;
        button {
          background-color: #fff;
          cursor: inherit;
        }
      }
      &:nth-child(1) {
        width: 10%;
      }
      &:nth-child(2) {
        width: 15%;
      }
      &:nth-child(3) {
        width: 37%;
      }
      &:nth-child(4) {
        width: 4%;
      }
      &:nth-child(5) {
        width: 10%;
      }
      &:nth-child(6) {
        width: 6%;
      }
      &:nth-child(7) {
        width: 6%;
      }
      &:nth-child(8) {
        width: 6%;
      }
      &:nth-child(9) {
        width: 6%;
      }
    }
    .row {
      // border-top: 1px solid #c2d1d9;
      border-bottom: 1px solid #c2d1d9;

      &:first-child {
        border-top: none;
      }
      &.new {
        border-bottom: 1px solid #c2d1d9;
      }
      height: 30px;
      position: relative;
      &:hover {
        background-color: #edf2f8;
        .col-btn {
          opacity: 1;
        }
      }
    }

    .table_head,
    .row {
      display: flex;
    }
    .col {
      &:nth-child(1) {
        border-left: 1px solid #c2d1d9;
      }
      &:nth-child(9) {
        border-right: 1px solid #c2d1d9;
      }
      &.error {
        border: 1px solid red;
      }
    }
  }
  .table_head {
    border-top: 1px solid #c2d1d9;
    border-bottom: 1px solid #c2d1d9;
  }
  .small-table {
    border-bottom: none;
    .row {
      &:last-child {
        border-bottom: 1px solid #c2d1d9;
      }
    }
  }

  .up-btn {
    position: absolute;
    bottom: -24%;
    right: 52px;
    transform: rotate(-90deg);
    color: #0253b3;
    font-family: 'Akrobat SemiBold';
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    cursor: pointer;
    background: transparent;
    display: flex;
    align-items: baseline;
    img {
      transform: rotate(90deg);
      padding: 0 5px;
    }
  }
}
