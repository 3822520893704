.transaction_history{
  margin-top: -20px;
  .transactions_columns {
    .table_header{
      .row_item{
        display: flex;
        align-items: center;
      }
    }
    .table_body{
      .table_row{
        & > :nth-child(2) {
          font-weight: 600;
        }
        & > :last-child {
          font-weight: 600;
        }
      }

    }
    .table_row {
      & > :first-child {
        width: 28%;
      }
      & > :nth-child(2) {
        width: 28%;
        .ru_value{
          font-weight: 500;
        }
      }
      & > :nth-child(3) {
        width: 28%;
      }
      & > :last-child {
        width: 16%;
        .ru_value{
          font-weight: 500;
        }
      }
    }
  }
  .pagination_wrapper{
    padding: 30px 0 0 0;
  }
}