.price-add{
  margin-bottom: 60px;
  background: #fff;
  padding: 40px 50px;
}

.tab-button{
  margin-bottom: 40px;
  button{
    margin-right: 50px;
    font-size: 32px;
    line-height: 40px;
    color: #C2D1D9;
    cursor: default;
    background: transparent;
    font-family: "Akrobat Regular", sans-serif;
  }
  .active{
    color: #334150;
  }
}

.btn-wrapper{
  padding-top: 30px;
  margin-top: 30px;
  border-top: 1px solid #C2D1D9;
  display: flex;
  align-items: center;
  width: 100%;
    .default_button_wrapper {
      margin-right: 14px;
    }
}