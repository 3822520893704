.my_orders{
  padding-top: 10px;
  .transactions_columns {
    padding-bottom: 40px;
    .table_header{
      .row_item{
        display: flex;
        align-items: center;
      }
    }
    .table_row {
      & > :first-child {
        width: 13%;
      }
      & > :nth-child(2) {
        width: 13%;
      }
      & > :nth-child(3) {
        width: 13%;
      }
      & > :nth-child(4) {
        width: 14%;
        display: flex;
      }
      & > :nth-child(5) {
        width: 14%;
      }
      & > :nth-child(6) {
        width: 14%;
      }
      & > :nth-child(7) {
        width: 14%;
      }
      & > :last-child {
        width: 5%;
        justify-content: flex-end;
        display: flex;
        button{
          cursor: pointer;
          background: transparent;
        }
      }
    }
    & > .test {
      overflow: hidden;
      max-height: 219px;
      &.isShow{
        max-height: 100%;
      }
    }
    & > .testOriginal{
      overflow: hidden;
      max-height: 438px;
      &.isShow{
        max-height: 100%;
      }
    }
    & > .btn_hide{
      margin-top: 20px;
    }
  }
  .amount{
    width: 93px;
    border: 1px solid rgba(194, 209, 217, 0.3);
    border-radius: 3px;
    height: 43px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button{
      cursor: pointer;
      background: transparent;
      height: 100%;
      display: flex;
      width: 35px;
      position: relative;

    }
    .minus{
      &:before{
        position: absolute;
        content: "";
        height: 1px;
        width: 10px;
        background: #334150;
        top:  calc(50% - 1px);
        left: calc(50% - 5px);
        opacity: 0.3;
      }
    }
    .plus{
      &:before{
        position: absolute;
        content: "";
        height: 1px;
        width: 10px;
        background: #334150;
        top:  calc(50% - 0.5px);
        left: calc(50% - 5px);
      }
      &:after{
        position: absolute;
        content: "";
        height: 10px;
        width: 1px;
        background: #334150;
        top:  calc(50% - 5px);
        left: calc(50% - 0.5px);
      }

    }
  }

  .pagination_wrapper{
    padding: 30px 0 0 0;
  }

}

.name_block{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #7F8FA4;
  font-size: 23px;
  line-height: 29px;
  span{
    color: #334150;
    font-weight: 600;
  }
  b{
    margin: 0 15px;
  }
  .original{
    margin-left: 30px;
    padding: 1px 8px;
    border: 1px solid #2CC197 ;
    color: #2CC197;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    text-transform: uppercase;
  }
  .analogue{
    margin-left: 30px;
    padding: 1px 8px;
    border: 1px solid #249AF3 ;
    color: #249AF3;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    text-transform: uppercase;
  }
}

.all_goods{
  margin-top: 20px;
  background: transparent;
  color: #0253B3;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  &:hover{
    opacity: 0.8;
  }
}

.my_orders_search{
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  input{
    border-radius: 3px;
    padding: 0 35px 0px 12px;
    height: 41px;
    width: 471px;
    border: 1px solid #C2D1D9;
    color: #334150;
    font-size: 14px;
    &::placeholder{
      color: #7F8FA4;
    }
  }
  .btn_search{
    position: absolute;
    cursor: pointer;
    right: 12px;
    top: calc(50% - 9px);
    background: transparent;
  }
  .search_products_list {
    & > :first-child {
      padding-top: 10px;
    }
    & > :last-child {
      border-bottom: none;
      padding-bottom: 10px;
    }
  }
  .my_orders_ul {
    width: 410px;
    li {
      box-sizing: border-box;
      border-bottom: 1px solid #C2D1D9;
      padding: 15px 0px!important;
      background: #fff!important;
      cursor: pointer;
    }
    & > :last-child {
      border-bottom: none;
    }
  }
}
.purchases_tabs{
  .my_orders{
    .table_row{
      height: 73px;
    }
  }
}

.wrapper_orders_block{
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  padding-bottom: 40px;
}
.wrapper_orders_block:last-of-type{
  border: none;
  padding-bottom: 0;
}


.my_orders_table{
  padding-top: 30px;
  hr{
    margin: 30px 0 0;
  }
}

.no_item_orders{
  padding: 100px 0 70px 0;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  .title_no_item{
    padding: 20px 0 10px 0;
    color: #334150;
    font-size: 16px;
  }
  .descriptions_no_item{
    color: #7F8FA4;
    font-size: 14px;
  }
}
.order-dialog-btn{
  .default_button_wrapper{
    text-transform: uppercase;
    margin: 0!important;
    >span{
      text-transform: uppercase;
    }
  }
}
.delete_border{
  border-bottom: none!important;
}

.search_product_item {
  padding: 15px 0;
  border-bottom: 1px solid #C2D1D9;
  cursor: pointer;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  &:hover {
    background-color: rgba(164, 231, 255, 0.1);
  }
}

.search_product_item_head {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  h4 {
    color: #01397C;
    font-family: "Akrobat Regular", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin-right: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  span {
    color: #7F8FA4;
    font-size: 12px;
    line-height: 15px;
  }
  .highlight {
    background: #a4e7ff;
  }
}

.my_orders_li {
  display: flex;
  justify-content: space-between;
  p {
    color: #7F8FA4;
    font-size: 14px;
    max-width: 70%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    & > span {
      &:first-of-type {
        color: #0A59B6;
      }
    }
    //&:last-of-type {
    //  font-size: 18px;
    //  color: #`334150`;
    //  span {
    //    color: #334150;
    //    font-family: Roboto, sans-serif;
    //    font-weight: 300;
    //  }
    //}
  }
  .my_orders_dot {
    margin-left: 10px;
    margin-right: 10px;
  }
}