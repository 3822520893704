.price-settings{
  .title{
    color: #334150;
    font-size: 32px;
    line-height: 40px;
    padding-bottom: 30px;
  }
  .columns-table{
    display: inline-block;
  }
}

.columns-block{
  display: flex;
  .columns-title{
    display: flex;
    flex-flow: column;
    margin-right: 100px;
    button{
      text-align: left;
      font-family: "Akrobat Regular", sans-serif;
      color: #7F8FA4;
      font-size: 16px;
      line-height: 20px;
      cursor: pointer;
      background: transparent;
      width: 100%;
      margin-bottom: 15px;
    }
    button:last-of-type{
      margin-bottom: 0;
    }
    .active-btn{
      color: #334150;
    }
    .success-btn{
      color: #16D761;
    }

  }
}


.price-table{
  width: 100%;
  overflow: hidden;
  .descriptions{
    padding-bottom: 10px;
    color: #334150;
    font-size: 12px;
    line-height: 15px;
  }
}
.scroll_block{
  max-width: 100%;
  overflow-x: auto;
  padding-bottom: 20px;
  &::-webkit-scrollbar {
    height: 6px;
    top: 10px;
  }
  &::-webkit-scrollbar-track {
    margin-top: 20px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    background: #EDF2F8;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #0253B3;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: #0253B3;
  }
}

.columns-table {
  border: 1px solid #C2D1D9;
  & > :first-child {
    border-top: none;
  }
  .table_row {
    & > :first-child {
      width: 30px;
      min-width: 30px;
    }
    div {
      width: 110px;
      min-width: 110px;
    }
    .table_cell {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

  }
  .table_row {
    height: 30px;
    border-top: 1px solid #C2D1D9;
    & > :first-child {
      color: #7F8FA4;
      font-size: 14px;
      line-height: 30px;
      text-align: center;
    }
    div, button {
      border-right: 1px solid #C2D1D9;
      height: 100%;
    }
    & > :last-child {
      border-right: none;
    }
  }
}

.table_head_cell {
  color: #7F8FA4;
  font-size: 12px;
  line-height: 30px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.table_cell {
  color: #334150;
  font-size: 12px;
  line-height: 30px;
  padding: 0 5px;
}

.settings-form{
  margin-top: 80px;
  .price-field{
    display: flex;
    align-items: center;
    .drop-down{
      margin-right: 50px;
      .select_wrapper {
        margin-left: 0;
        position: relative;
        .span-label {
          position: absolute;
          top: -18px;
          left: 0;
          font-size: 12px;
          color: #7f8fa4;
        }
      }
    }
    .custom_input_wrapper{
      max-width: 53px;
      .custom_input{
        padding: 0;
        input {
          padding-right: 0;
          color: #334150;
        }
      }
    }
  }
}
.dialog_wrapper.dialog_wrapper_long {
  overflow: hidden;
}
.undefined-producers-wrapper {
  min-height: 25vh;
  margin-top: 20px;
  margin-bottom: 20px;
  overflow-y: auto;
  .undefined-producers-item {
    display: block;
    margin-bottom: 10px;
    &>input {
      border: 1px solid #C2D1D9;
      border-radius: 3px;
      height: 41px;
      width: 300px;
      line-height: 41px;
      padding: 0 12px;
      color: #01397C;
      font-family: "Akrobat Regular", sans-serif;
      font-size: 14px;
      font-weight: 300;
      margin-right: 19px;
      &::placeholder {
        color: #7F8FA4;
      }
      &:disabled {
        background-color: #FFFFFF;
      }
    }
    img {
      width: 11px;
      height: 8px;
      margin-right: 19px;
    }
    div.select_wrapper {
      margin-left: 0;
    }
  }
  .select_component .select__control .select__input input {

  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: rgba(2,83,179,0.05);
  }

  &::-webkit-scrollbar {
    width: 7px;
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(2,83,179,1);
  }

}

.markup{
  padding-top: 30px;
  margin-top: 30px;
  border-top: 1px solid #C2D1D9;
  .markup-stepped {
    .custom_input_wrapper .custom_input input {
      color: #334150;
      padding-right: 0;
    }
  }
}

.col_btn {
  height: 30px;
  //width: 110px;
  min-width: 110px;
  text-align: center;
  cursor: pointer;
  background: #EDF2F8;
  color: #7F8FA4;
  font-size: 14px;
  line-height: 17px;
  border-left: none;
  border-bottom: none;
  &:disabled {
    cursor: default;
    background-color: #EDF2F8 !important;
    &:hover {
      background: #EDF2F8;
    }
  }
  &:hover {
    background: rgba(2, 83, 179, 0.1);
    & ~ div {
      background: rgba(2, 83, 179, 0.1);
    }
  }
}

div {
  .custom_radio{
    margin-left: 0;
    margin-bottom: 20px;
    >span{
      padding: 0;
      margin-right: 5px;
      color: #334150;
    }
  }
}


.markup-title{
  color: #334150;
  font-size: 32px;
  line-height: 40px;
  padding-bottom: 30px;
}

.markup-fixed{
  padding: 5px 0 25px 22px;
  .custom_input_wrapper{
    max-width: 93px;
  }
}

.markup-stepped{
  padding: 5px 0 0 22px;
  .custom_input_wrapper{
    max-width: 93px;
    margin-right: 20px;
  }
  >div{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .custom_input_wrapper:first-of-type{
      .custom_input{
        padding-right: 0;
        input{
          min-width: 39px;
          text-align: right;
        }
      }
    }
  }
  .other-markup{
    .custom_input_wrapper:first-of-type{
      .custom_input{
        input{
          text-align: left;
        }
      }
    }
  }
  .markup-btn{
    margin-left: 30px;
    background: transparent;
    cursor: pointer;
    color: #0253B3;
    font-size: 16px;
    text-align: right;
    &.markup-btn-remove {
      color: #FF0000;
    }
  }
  .other-markup{
    margin: 0;
    padding-top: 20px;
  }
}