.up-btn {
    position: absolute;
    bottom: 30px;
    right: 70px;
    transform: rotate(-90deg);
    color: #0253b3;
    font-family: "Akrobat SemiBold";
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    cursor: pointer;
    background: transparent;
    display: flex;
    align-items: baseline;
    img {
        transform: rotate(90deg);
        padding: 0 5px;
    }
}

.purchases {
    padding: 40px 50px 40px;
    background: #fff;
    margin-bottom: 70px;
    .my_orders {
        .transactions_columns {
            padding-bottom: 40px;
            .table_header {
                .row_item {
                    display: flex;
                    align-items: center;
                }
            }
            .table_row {
                & > :first-child {
                    width: 25%;
                }
                & > :nth-child(2) {
                    width: 25%;
                    display: flex;
                    flex-direction: column;
                }
                & > :nth-child(3) {
                    width: 25%;
                }
                & > :nth-child(4) {
                    width: 20%;
                    display: flex;
                }
                & > :last-child {
                    width: 5%;
                    justify-content: flex-end;
                    display: flex;
                    button {
                        cursor: pointer;
                        background: transparent;
                    }
                }
            }
        }
    }
}

.purchases_page {
    position: relative;
}

.purchases_tabs {
    .tabs_block_purchases {
        margin-bottom: 42px;
        button {
            color: #7f8fa4;
            font-size: 16px;
            position: relative;
            margin-right: 30px;
            background: transparent;
            cursor: pointer;
            -webkit-transition: all 0.3s linear;
            -moz-transition: all 0.3s linear;
            -ms-transition: all 0.3s linear;
            -o-transition: all 0.3s linear;
            transition: all 0.3s linear;
            &:hover {
                color: #334150;
            }
        }
        .active {
            color: #334150;
            &:before {
                position: absolute;
                content: "";
                left: 0;
                bottom: -10px;
                height: 2px;
                width: 100%;
                background: #0253b3;
            }
        }
    }

    .table_container {
        .table_header .table_header {
            display: flex;
        }
    }

    .table_header {
        .row_item {
            color: #7f8fa4;
            font-size: 12px;
        }
    }

    .table_body {
        .row_item {
            color: #334150;
            font-size: 16px;
        }
    }

    .table_row {
        width: 100%;
        display: flex;
        align-items: center;
        height: 60px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
}
