.order_section_title {
    color: #334150;
    font-size: 32px;
    line-height: 40px;
}

.add_order_block {
    margin: 30px 0;
    .custom_input_wrapper {
        margin-right: 50px;
    }
    & > :last-child {
        margin: 0;
    }
}

.edit_btn_wrapper {
    margin-top: 30px;
    > div {
        display: flex;
        align-items: center;
        & > :last-child {
            margin: 0;
        }
    }

    .default_button_wrapper {
        margin-right: 15px;
    }
}
.download_xlsx {
    color: #0253b3;
    font-size: 14px;
    text-decoration: underline;
    background: none;
    display: block;
    margin-bottom: 30px;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    &:hover {
        text-decoration: none;
        opacity: 0.7;
        cursor: pointer;
    }
}

.add_order_info {
    color: #7f8fa4;
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
    margin: 20px 0 30px;
}

.orders_summary {
    margin: 30px 0;
    & > div {
        margin-right: 100px;
    }
    & > :last-child {
        margin: 5px 0 0;
    }
    div {
        .block_label {
            display: block;
            margin-bottom: 15px;
        }
        .block_value_currency {
            font-size: 23px;
        }
    }
}

.order_product_item {
    margin: 30px 0;
    display: flex;
    .custom_input_wrapper {
        max-width: 50%;
        margin-right: 30px;
    }
    .red_btn {
        margin-left: 40px;
    }
    &.order_product_item_error {
        .custom_count_field .count_field {
            border-color: #ff0000;
        }
    }
}
.auth_error.error_after_button {
    color: #ff0000;
    font-size: 12px;
    width: auto;
    position: static;
    display: flex;
    align-items: center;
    svg {
        margin-right: 5px;
    }
}

.add_product {
    .search_product_wrapper {
        width: 100%;
        margin-right: 50px;
    }
}
