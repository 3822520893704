.custom_count_field {
  display: inline-block;
}

.count_field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgba(194, 209, 217, 0.3)!important;
  border-radius: 3px;
  height: 40px;
  &.count_field_disabled {
    opacity: 0.7;
  }
  input {
    font-family: "Akrobat Regular", sans-serif;
    text-align: center;
    font-size: 16px;
    line-height: 18px;
    color: #334150;
    background-color: transparent;
    width: 30px;
    &:disabled {
      color: #334150;
    }
  }
  button {
    height: 100%;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    background-color: transparent;
    font-size: 30px;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    &:disabled {
      opacity: 0.7;
      cursor: default;
      &:hover {
        background-color: transparent;
      }
    }
    &:hover {
      background-color: rgba(51, 65, 80, 0.1);
    }
    svg {
      font-size: 20px;
      fill: #334150;
    }
  }
}