.requests{
  .add_btn{
    img{
      margin: 0 10px 0 0;
    }
  }
}

.requests{
  .requests_table{
    padding: 0;
  }
  .transactions_columns {
    .table_header{
      .row_item{
        display: flex;
        align-items: center;
      }
    }
    .table_row {
      align-items: flex-start;
      height: auto;
      flex-flow: column;
      text-decoration: none;
      .row_orders{
        cursor: pointer;
        width: 100%;
        display: flex;
        height: 60px;
        align-items: center;
        & > :first-child {
          width: 30%;
        }
        & > :nth-child(2) {
          width: 15%;
        }
        & > :nth-child(3) {
          width: 15%;
        }
        & > :nth-child(4) {
          width: 17%;
        }
        & > :nth-child(5) {
          width: 17%;
        }
        & > :nth-child(6) {
          width: 17%;
        }
        & > :nth-child(7) {
          width: 10%;
        }
        & > :nth-child(8) {
          width: 16%;
        }
      }
    }
  }
  .info_items{
    padding: 0px 0 20px;
    display: flex;
    width: 100%;
    flex-flow: column;
    .descriptions{
      padding-bottom: 10px;
      color: #7F8FA4;
      font-size: 12px;
    }
    .comment{
      word-break: break-all;
      color: #334150;
      font-size: 16px;
      padding-bottom: 0;
    }
  }
  .block_status{
    display: flex;
    align-items: center;
  }
  .status_orders{
    height: 6px;
    width: 6px;
    display: flex;
    border-radius: 50%;
    margin-right: 5px;
  }
  .blue{
    background: #249AF3;
  }
  .green{
    background: #16D761;
  }
  .red{
    background: #D0021B;
  }
  .pagination_wrapper{
    padding: 30px 0 0 0;
  }
}
