.search_wrapper {
  position: relative;
  background-color: #FFFFFF;
  border-radius: 3px;
  border: 1px solid #C2D1D9;
  height: 40px;
  width: 100%;
  display: flex;
  &.search_wrapper_small {
    width: 275px;
    justify-content: space-between;
    &>div:first-child {
      width: calc(100% - 34px);
    }
    .search_input_icon {
      display: flex;
      align-items: center;
      width: 34px;
    }
  }
  input {
    width: calc(100% - 34px);
    height: 38px;
    line-height: 38px;
    padding: 0 12px;
    font-size: 14px;
    font-family: "Akrobat Regular", sans-serif;
    &::placeholder {
      color: #7F8FA4;
    }
  }
  button {
    padding: 5px 7px;
    border-radius: 0;
    svg {
      font-size: 20px;
      fill: #C2D1D9;
    }
  }
}