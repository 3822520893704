.preloader_wrapper {
  position: absolute;
  top: 80px;
  left: 0;
  width: 100%;
  z-index: 9999;
  & > div {
    height: 3px;
    background-color: #01397C;
    &> div {
      background-color: #ffffff;
    }
  }
}