.popper_wrapper {
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
  color: #334150;
  font-family: "Akrobat Regular", sans-serif;
  font-size: 14px;
  line-height: 17px;
  padding: 5px 20px;
  width: 420px;
  position: relative;
  margin-top: 5px;
  &::-webkit-scrollbar {
    height: 6px;
    top: 10px;
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    -webkit-border-radius: 2px;
    border-radius: 2px;
    background: #EDF2F8;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #0253B3;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: #0253B3;
  }
}

.popper_loader {
  height: 72px!important;
}