.price_title {
  margin: 12px 0 10px;
  h5 {
    color: #01397c;
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.price_btn_wrapper {
  display: flex;
  justify-content: space-between;
  margin: 14px 0 10px;
  .tick_wrapper {
    color: #7f8fa4;
    font-size: 14px;
    line-height: 17px;
    img {
      margin-right: 5px;
    }
  }
}

.refresh_price_btn {
  background-color: transparent;
  color: #0253b3;
  font-family: "Akrobat Regular", sans-serif;
  font-size: 14px;
  line-height: 17px;
  text-decoration: underline;
  cursor: pointer;
  &.text-green {
    color: #16d761;
    text-decoration: none;
    cursor: default;
    img {
      margin-right: 5px;
    }
  }
  &:hover {
    text-decoration: none;
  }
}

.delete_price_btn {
  background-color: transparent;
  color: #d0021b;
  font-family: "Akrobat Regular", sans-serif;
  font-size: 14px;
  line-height: 17px;
  text-decoration: none;
  cursor: pointer;
}

.info_card {
  &.has-inner {
    cursor: pointer;
  }
}
