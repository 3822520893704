.price-list {
  .dashboard-section_content {
    display: block;
    padding-top: 0;
    &:after {
      content: "";
      display: table;
      clear: both;
    }
    .review-item {
      height: 250px;
    }
    .price-item {
      height: 265px;
    }
    .price-item,
    .review-item {
      position: relative;
      float: left;
      margin-right: 2%;
      width: 23.5%;
      &:nth-child(4n) {
        margin-right: 0;
      }
      .block_value_extra {
        height: 40px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
