.content-block{
  background: #fff;
  padding: 10px 50px 0;
  .no-items {
    padding-top: 0;
  }
  &.content-block-reviews {
    margin-bottom: 30px;
  }
}

.info-time{
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  img{
    margin-right: 5px;
  }
  span{
    color: #7F8FA4;
    font-size: 12px;
    font-weight: 300;
    line-height: 15px;
  }
}

.reviews-wrapper{
  .name{
    color: #01397C;
    font-size: 23px;
    font-weight: 600;
    line-height: 29px;
  }
}

.rating{
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  span{
    color: #7F8FA4;
    font-size: 14px;
    padding-left: 5px;
  }
}
.reviews:last-of-type{
  border: none;
  padding: 30px 0 40px;;
}
.reviews{
  padding: 30px 0 30px;
  border-bottom: 1px solid #C2D1D9;
  .rating_wrapper {
    margin-bottom: 10px;
  }
  .descriptions{
    color: #334150;
    font-size: 14px;
    line-height: 17px;
    padding: 0 0 15px;
    word-break: break-all;
    width: 650px;
    &:last-of-type {
      padding-bottom: 0;
      word-break: break-all;
    }
    span {
      font-family: "Akrobat SemiBold", sans-serif;
    }
  }
  textarea{
    font-family: "Akrobat Regular", sans-serif;
    resize: none;
    box-sizing: border-box;
    height: 60px;
    font-size: 14px;
    max-width: 650px;
    width: 100%;
    border: 1px solid #C2D1D9;
    border-radius: 3px;
    padding: 12px 11px;
    margin-bottom: 10px;
    &::placeholder{
      font-family: "Akrobat Regular", sans-serif;
      color: #7F8FA4;
      font-size: 14px;
      font-weight: 300;
    }
  }
  .default_button_wrapper .default_button.default_button_contained {
    background-color: #0253B3;
    color: #ffffff;
    border:none;
  }
}