.custom_time_field {
  & > div > div {
    border: 1px solid #C2D1D9;
    border-radius: 3px;
    height: 40px;
    min-width: 53px;
    font-family: "Akrobat Light", sans-serif;
    input[type="time"]::-webkit-clear-button {
      display: none;
    }
    input {
      padding: 0 10px;
      font-size: 14px;
      color: #7F8FA4;
      text-align: center;
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      &::-webkit-search-decoration,
      &::-webkit-search-cancel-button,
      &::-webkit-search-results-button,
      &::-webkit-search-results-decoration { display: none; }
      &::-ms-clear {  display: none; width : 0; height: 0; }
      &::-ms-reveal {  display: none; width : 0; height: 0; }
    }
    &:before, &:after {
      display: none;
    }
  }
}