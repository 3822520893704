.review_title {
  margin-top: 15px;
  h5 {
    color: #01397C;
    font-size: 23px;
    font-weight: 600;
    line-height: 29px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.review_content {
  color: #334150;
  font-size: 14px;
  line-height: 17px;
  margin: 15px 0;
  height: 70px;
  overflow: hidden;
}

.review_btn_wrapper {
  text-align: center;
  padding: 12px 0;
  button {
    background-color: transparent;
    color: #0253B3;
    font-family: "Akrobat Regular", sans-serif;
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    &:hover {
      text-decoration: none;
    }
  }
}